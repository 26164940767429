import React, {useState} from "react";
import {GSPSSubmitButtonStyle} from "Components/Common/GSPSSubmitButton.styles";
import {UserPermissions} from "Models/User/UserUtils";
import * as TimeZonesUtils from "Services/TimeZonesUtils";
import {useCurrentUser} from "Models/User/useCurrentUser";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs";
import {GSPSDataDrivenTable} from "Components/DataTable/GSPSDataDrivenTable";
import {useFetchUsersQuery} from "api/UserTableAPI";
import {UserCreator} from "./UserCreator";
import {UserEditor} from "./UserEditor";
import {Button} from "react-bootstrap";
import {NavLink} from "react-router-dom";

export const Users = () => {
    useCurrentUser();
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [userID, setUserID] = useState(null);
    const canManageUsers = UserPermissions.useIsAdmin();

    const usersColumns = {
        "Username": "email",
        "Title": "role.name",
        "Permissions": "group_permission.name",
        "Status": "is_active",
        "Created on": "created_at",
    };

    const dataParser = {
        email: (user) =>
            <NavLink
                onClick={() => {
                    setUserID(user.id);
                    setShowEditModal(true);
                }}
            >
                {user.formattedValue}
            </NavLink>,
        created_at: (user) => TimeZonesUtils.convertToEst((user.row.created_at.toString()), true),
    };


    return (
        <div className="users">
            <GSPSBreadCrumbs />
            <div className="container">
                <GSPSDataDrivenTable
                    columns={usersColumns}
                    dataParser={dataParser}
                    title="Users"
                    tableAdminBtn={canManageUsers && <GSPSSubmitButtonStyle
                        onClick={() => setShowAddModal(true)}
                        className="cta-btn"
                    >
                        Add New User
                    </GSPSSubmitButtonStyle>}
                    useQuery={useFetchUsersQuery}
                />
            </div>
            {showAddModal && (
                <UserCreator
                    show={showAddModal}
                    setShow={setShowAddModal}
                />
            )}
            {showEditModal && userID && (
                <UserEditor
                    show={showEditModal}
                    setShow={setShowEditModal}
                    userID={userID}
                />
            )}
        </div>
    );
};
