import React, {useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {GSPSSubmitButtonStyle} from "Components/Common/GSPSSubmitButton.styles";
import {useCurrentUser} from "Models/User/useCurrentUser";
import {UserPermissionGroupIDs} from "Models/User/UserConstants";
import {insertNotification} from "Utils/NotificationsUtils";
import {UserPermissions} from "Models/User/UserUtils";
import AuditsIntroSection from "./AuditsIntroSection";
import GSPSMenu from "Components/DataTable/GSPSMenu";
import CircleIcon from "@mui/icons-material/Circle";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SendIcon from "@mui/icons-material/Send";
import HistoryIcon from "@mui/icons-material/History";
import {AuditsPage} from "Styles/Audits.styles";
import {Box, Tab, Tabs} from "@mui/material";
import {useArchiveAuditMutation, useCloneAuditMutation, useDeleteAuditMutation, useFetchAuditsQuery, useUnarchiveAuditMutation} from "api/AuditTableAPI";
import {GSPSDataDrivenTable} from "Components/DataTable/GSPSDataDrivenTable";

const CustomTabPanel = (props) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{p: 0}}>{children}</Box>}
        </div>
    );
};

const a11yProps = (index) => {
    return {
        "id": `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

export const Audits = ({locationID = null, UpdateCurrentUser}) => {
    const currentUser = useCurrentUser();
    const navigate = useNavigate();
    const canManageUsers = UserPermissions.useIsAdmin();
    const [isUserAdmin, setIsUserAdmin] = useState();
    const [tableKey, setTableKey] = useState(0);
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    UpdateCurrentUser(currentUser);
    useEffect(() => {
        if (currentUser?.group_permission) {
            const isAdmin = UserPermissionGroupIDs.SUPER_ADMIN === currentUser.group_permission.id;
            const isGroupAdmin = UserPermissionGroupIDs.GROUP_ADMIN === currentUser.group_permission.id;
            setIsUserAdmin(isAdmin || isGroupAdmin);
        }
    }, [currentUser]);

    const AUDIT_COLUMNS = {
        "Audit Name": "name",
        "Account": "account_name",
        "Location": "full_address",
        "Audit Status": "audit_status",
        "User": "created_by_full_name",
        "Created on": "created_at",
        "Updated on": "updated_at",
        "Actions": "actions",
    };

    const dataParser = {
        name: (audit) => {
            const linkPath =
                (audit.row.audit_status?.name === "In Progress" ||
                    audit.row.audit_status?.name === "Edits Required" ||
                    audit.row.audit_status?.name === "In Review") ?
                    `/audits/creator?auditId=${audit.id}` :
                    `/audits/${audit.id}/?report=false`;

            return (
                <NavLink to={linkPath} value={audit.id}>
                    {audit.row.name}
                </NavLink>);
        },
        actions: (audit) => {
            const actionsList = tabValue === 1 ? [
                {
                    text: "Unarchive",
                    icon: <UnarchiveIcon fontSize={"medium"} />,
                    handleClick: () => handleUnarchive(audit),
                },
                {
                    text: "Delete",
                    icon: <DeleteForeverIcon fontSize={"medium"} />,
                    handleClick: () => handleDelete(audit),
                },
            ] : [
                {
                    text: "Send Report",
                    icon: <SendIcon fontSize={"medium"} />,
                    handleClick: () => navigate(`/audits/${audit.id}/`),
                    disabled: !isUserAdmin || !(audit.row.audit_status.name === "Completed"),
                },
                {
                    text: "Clone",
                    icon: <CopyAllIcon fontSize={"medium"} />,
                    handleClick: () => handleClone(audit),
                },
                {
                    text: "Archive",
                    icon: <ArchiveIcon fontSize={"medium"} />,
                    handleClick: () => handleArchive(audit),
                },
                {
                    text: "Activity Logs",
                    icon: <HistoryIcon fontSize={"medium"} />,
                    handleClick: () => navigate(`/audits/logs/${audit.id}`),
                    disabled: !isUserAdmin,
                },
            ];

            return <GSPSMenu actions={actionsList} />;
        },
        audit_status: (audit) => {
            const status = audit.row.audit_status.name;
            let statusClass = "auditsCreated";
            switch (status) {
                case "Completed":
                    statusClass = "auditsCompleted";
                    break;
                case "In Review":
                    statusClass = "auditsInReview";
                    break;
                case "In Progress":
                    statusClass = "auditsCreated";
                    break;
                default:
                    statusClass = "auditsRequestEdits";
                    break;
            }

            return (
                <div className={`d-flex align-items-center ${statusClass}`}>
                    <CircleIcon fontSize="small" className="me-2" />
                    <span>
                        {status}
                    </span>
                </div>
            );
        },
    };

    const [archiveAudit] = useArchiveAuditMutation();
    const [unarchiveAudit] = useUnarchiveAuditMutation();
    const [deleteAudit] = useDeleteAuditMutation();
    const [cloneAudit] = useCloneAuditMutation();

    const handleClone = async (audit) => {
        try {
            await cloneAudit(audit.id).unwrap();
            insertNotification("Success", `Audit ${audit.id} has been cloned.`, "success");
        } catch (err) {
            insertNotification("Error", `Can't clone audit: ${err.message}`, "error");
        }
    };

    const handleArchive = async (audit) => {
        try {
            await archiveAudit(audit.id).unwrap();
            insertNotification("Success", `Audit ${audit.id} has been archived.`, "success");
        } catch (err) {
            insertNotification("Error", `Can't archive audit: ${err.message}`, "error");
        }
    };

    const handleUnarchive = async (audit) => {
        try {
            await unarchiveAudit(audit.id).unwrap();
            insertNotification("Success", `Audit ${audit.id} is now active.`, "success");
        } catch (err) {
            insertNotification("Error", `Can't unarchive audit: ${err.message}`, "error");
        }
    };

    const handleDelete = async (audit) => {
        try {
            await deleteAudit(audit.id).unwrap();
            insertNotification("Success", `Audit ${audit.id} is deleted successfully`, "success");
        } catch (err) {
            insertNotification("Error", `Can't delete Audit ${audit.id}. Reason: ${err.message}`, "error");
        }
    };

    return (
        <AuditsPage>
            <AuditsIntroSection updateStats={tableKey} />
            <div className="container position-relative z-2">
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs">
                    <Tab label="Active" {...a11yProps(0)} className="subtitle-sm" />
                    <Tab label="Archived" {...a11yProps(1)} className="subtitle-sm" />
                </Tabs>

                <CustomTabPanel value={tabValue} index={0}>
                    <GSPSDataDrivenTable
                        title="Audit Inventory"
                        columns={AUDIT_COLUMNS}
                        dataParser={dataParser}
                        tableAdminBtn={<GSPSSubmitButtonStyle
                            onClick={() => navigate("/audits/creator")}
                            className="cta-btn"
                        >
                            Audit this location
                        </GSPSSubmitButtonStyle>}
                        useQuery={useFetchAuditsQuery}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                    <GSPSDataDrivenTable
                        title="Audit Inventory (archived)"
                        columns={AUDIT_COLUMNS}
                        dataParser={dataParser}
                        useQuery={useFetchAuditsQuery}
                        active={false}
                        tableAdminBtn={<GSPSSubmitButtonStyle
                            onClick={() => navigate("/audits/creator")}
                            className="cta-btn"
                        >
                            Create New Audit
                        </GSPSSubmitButtonStyle>}
                    />
                </CustomTabPanel>

            </div>
        </AuditsPage>
    );
};

Audits.propTypes = {
    locationID: PropTypes.string,
    UpdateCurrentUser: PropTypes.func,
};
